<template>
  <div>
    <v-btn dark color="orange" elevation="0" :loading="loadingSum" :disabled="!isRefundlable" @click="getSumToRefund">refund</v-btn>
    <v-dialog
        v-model="refundDialog"
        width="500"
    >
      <v-card>
        <v-card-title class="text-h5 orange white--text">
          Refund
        </v-card-title>

        <v-card-text class="mt-5 text-h4">
          {{ refund ? `Sum to refund: ${refund}` : `Can't refund this ticket!` }}
        </v-card-text>

        <v-divider></v-divider>

        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
              color="primary"
              text
              @click="refundDialog = false"
          >
            Cancel
          </v-btn>
          <v-btn v-if="refund"
                 color="primary"
                 text :loading="loadingRefund"
                 @click="confirmRefund"
          >
            Refund
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <shift-dialog v-model="shiftError"></shift-dialog>
  </div>
</template>

<script>
import ShiftDialog from "./ShiftDialog";

export default {
  name: "RefundBtn",
  components: {ShiftDialog},
  props: ['regnum', 'surname', 'pult', 'isRefundlable'],
  data() {
    return {
      refundDialog: false,
      loadingSum: false,
      loadingRefund: false,
      success: false,
      shiftError: false,
      refund: 0,
    }
  },
  methods: {
    async getSumToRefund() {
      this.loadingSum = true
      let resp = await this.$axios.get(`/sumtorefund?regnum=` + this.regnum + '&surname=' + this.surname + '&pult=' + this.pult)
      this.refund = resp.data ? resp.data.Text + ' ' + resp.data.Curr : ''
      this.loadingSum = false
      this.refundDialog = true
    },
    async confirmRefund() {
      if (!this.$store.getters.shiftOpen || this.$store.getters.shiftExpired) {
        this.shiftError = true
        return
      }

      this.loadingRefund = true
      let resp = await this.$axios.get(`/confirmrefund?regnum=` + this.regnum + '&surname=' + this.surname + '&pult=' + this.pult)
      console.log(resp)
      this.success = resp.data
      if (this.success) this.$emit('refund')
      this.loadingRefund = false
      this.refundDialog = false
    }
  }
}
</script>

<style scoped>

</style>
