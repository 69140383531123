<template>
  <v-container class="mt-16">
    <overlay-loader overlay></overlay-loader>
    <v-row justify="center" align-content="center">
      <v-col>
        <v-alert text type="success" prominent width="100%" color="success">
          <span>Успешно!</span>
        </v-alert>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import RefundBtn from "@/components/UI/RefundBtn";
import ErrorModal from "@/components/UI/ErrorModal";
import ShiftDialog from "@/components/UI/ShiftDialog";
import OverlayLoader from "@/components/UI/OverlayLoader";

export default {
  name: "SuccessRailway",
  components: { ShiftDialog, RefundBtn, OverlayLoader, ErrorModal },
};
</script>

<style scoped></style>
